<template>
  <div class="box text-center">
    <p class="text-center text_info">{{ $t('login.msisdnLogin') }}</p>
    <div class="input">
      <input
        :placeholder="`+ ${getCountryCode}`"
        type="text"
        class="game_input"
        v-model="phone"
        @input="valueChanged"
      />
    </div>

    <div class="my-4" style="width: 100%">
      <button
        @click="login()"
        style="width: 100%"
        :class="{ game_btn: true, activeBtn: isChanged }"
        id="sub_btn"
        :disabled="btnDisabled"
      >
        {{ btnDisabled ? $t('login.loading') : $t('sidebar.login') }}
      </button>
      <!-- <button
        :class="{ game_btn: true }"
        :disabled="btnDisabled"
        class="d-inline"
        @click="redirectToGoogle"
      >
        {{ $t('login.exit') }}
      </button> -->
    </div>
    <!-- <div style="font-size: 14px; margin-top: 10px">
      {{ $t('login.header') }}
    </div> -->
    <!-- <div
      style="text-anchor: middle; font-size: 0.6rem; color: #d1d0cf"
      :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
    >
      {{ $t('login.subTermB') }}
    </div> -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        text-anchor: middle;
        font-size: 1rem;
        color: #d1d0cf;
      "
      :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
    >
      <p>
        {{ $t('login.noAccount') }}
        &nbsp;
      </p>
      <router-link style="text-decoration: underline" to="/login-etisalat">
        {{ $t('login.subscribe') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AlertService from '@/services/errors'

// import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

export default {
  data () {
    return {
      phone: '+964',
      isChanged: false,
      btnDisabled: false,
      baseUrl: 'https://high-fit.net:3459/api/v1'
    }
  },
  methods: {
    redirectToGoogle () {
      window.location.href = 'https://www.google.com'
    },
    valueChanged (Val) {
      this.isChanged = true
    },

    getTimeStamp () {
      return parseInt(new Date().getTime() / 1000)
    },

    async login () {
      this.btnDisabled = true
      const phoneNumber =
        this.getCountryCode +
        this.phone.replaceAll('+' + this.getCountryCode, '')
      if (phoneNumber.length < 12) {
        this.btnDisabled = false
        AlertService.errorMessage('Invalid Phone')
        return
      }

      //   const trackingId = 'highFt-fit' + uuidv4()
      try {
        const res = await axios.post(this.baseUrl + '/auth/etisalat/login', {
          msisdn: phoneNumber
          // tracking_id: trackingId
        })
        const data = res.data
        if (data.code === 22) {
          // code === 22 meaning user is subscribed

          localStorage.setItem('highFitToken', data.data.access_token)
          this.$router.push('/')
        }
      } catch (error) {
        AlertService.errorMessage(error.response.data.message)
        this.$router.push('/login-etisalat')
      } finally {
        this.btnDisabled = false
      }
    }
  },
  mounted () {
    this.$i18n.locale = localStorage.getItem('lang') ?? 'en'
    this.phone = '+' + this.getCountryCode
  },
  computed: {
    getCountryCode: function () {
      return localStorage.getItem('countryCode')
    }
  }
}
</script>

<style>
img {
  width: auto;
  height: auto;
}
</style>
